import React from "react"
import { Container } from "semantic-ui-react"

import Layout from "../components/layout-desktop"
import SEO from "../components/seo"

import ManufacturerIntro from "../components/manufacturers/manufacturer-intro"
import ManufacturerRef from "../components/manufacturers/manufacturer-ref"

const Manufacturers = () => (
  <Layout>
    <SEO
      title="Connected Manufacturers - SixEye"
      description="Become a SixEye Connected manufacturer and let your customers manage your products remotely. There's no charge to integrate."
    />
    <ManufacturerRef title="Connected Manufacturers" headerAs="h1" />
    <Container
      text
      style={{
        marginTop: `2em`,
      }}
    >
      <ManufacturerIntro headerAs="h2" />
    </Container>
  </Layout>
)

export default Manufacturers
