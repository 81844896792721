import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Grid, Header } from "semantic-ui-react"

const ManufacturerRef = props => (
  <>
    {props.title && (
      <Header as={props.headerAs ? props.headerAs : "h1"} textAlign="center">
        {props.title}
      </Header>
    )}
    <Grid columns="equal" stackable verticalAlign="middle">
      {props.manufacturers.map(({ node }) => (
        <Grid.Column key={node.id} textAlign="center">
          <Img
            style={{
              maxHeight: `44px`,
              maxWidth: `${node.image.childImageSharp.fluid.aspectRatio *
                44}px`,
              margin: `auto`,
            }}
            fluid={node.image.childImageSharp.fluid}
            alt={`${node.name} logo`}
          />
        </Grid.Column>
      ))}
    </Grid>
  </>
)

ManufacturerRef.propTypes = {
  manufacturers: PropTypes.array.isRequired,
  title: PropTypes.string,
  headerAs: PropTypes.string,
}

const ManufacturerRefContainer = props => {
  const data = useStaticQuery(graphql`
    query {
      manufacturers: allConnectedManufacturersJson {
        edges {
          node {
            id
            name
            image {
              childImageSharp {
                fluid(maxHeight: 44) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  `)
  const edges = data.manufacturers.edges
  return <ManufacturerRef {...props} manufacturers={edges} />
}

export default ManufacturerRefContainer
