import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Header } from "semantic-ui-react"

import renderAst from "../render-markdown"

export default ({ headerAs }) => {
  const data = useStaticQuery(graphql`
    query manufacturerIntro {
      markdownRemark(
        fileAbsolutePath: {
          regex: "/src/content/manufacturers/manufacturer-intro/"
        }
      ) {
        htmlAst
        frontmatter {
          title
        }
      }
    }
  `)
  const markdownData = data.markdownRemark
  return (
    <>
      <Header as={headerAs} textAlign="center">
        {markdownData.frontmatter.title}
      </Header>
      {renderAst(markdownData.htmlAst)}
    </>
  )
}
